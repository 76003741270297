
a:visited, a:hover, a:focus {
  text-decoration: none;

}

@font-face {
  font-family: 'ChunkFive';
  font-style: normal;
  font-weight: normal;
  src: local('/fonts/ChunkFive-Roman'), url('/fonts/Chunkfive.woff') format('woff');
}

p {
  line-height: 1.8em;
}

h2 {
  margin-top: 50px;
}

nav.navbar-default {

  border-radius: 0;
  margin-bottom: 0;

  .navbar-nav > li {
    a, a:hover, a:focus, a:visited {
      font-family: ChunkFive, sans-serif;
      color: white;
      text-decoration: none;
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  button.navbar-toggle.collapsed {
    color: white;
    border-color: currentColor;

    &:hover, &:focus, &:active {
      background-color: darken($main-color, 20%);
    }
  }

  .navbar-toggle .icon-bar {
    background-color: white;
  }

  #logo {
    max-width: 289px;
    margin-top: -7px;
  }
}

@media (max-width: $screen-sm-max) {
  img#logo {
    height: 27px;
  }
}

header#default, footer {
  background: $black-background url(/images/background.jpg) center center no-repeat;
  background-size: cover;
  color: white;
  padding: 80px 0 0;

  a {
    color: currentColor;

    &:hover, &:focus {
      color: currentColor;
      text-decoration: underline;
      text-decoration-color: $main-color;
    }
  }
}


header {
  padding-bottom: 0;
  overflow: hidden;

  h1 {
    font-size: 32px;
  }

  p {
    text-align: justify;
  }

  div.col-md-8 {
    text-align: right;
  }
}


footer {

  padding: 80px 0;

  h2 {
    text-align: left;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      &:before {
        content: ">";
        color: $main-color;
        padding-right: 15px
      }
    }
  }
}


section {
  padding: 80px 0;
}

h1, h2, h3, h4 {
  font-family: ChunkFive, sans-serif;
}

h2 {
  text-align: center;
}

hr {
  border: 2px solid;
  width: 200px;
  margin: 40px auto;
}

h4.media-heading {
  color: $main-color
}

p.main-color {
  color: $main-color;
}


::selection {
  color: white !important;
  background: $main-color !important;
}

::-moz-selection {
  color: white !important;
  background: $main-color !important;
}

.main-color {
  color: $main-color;
}

div#login {
  margin: 50px auto;
}


p.count {
  text-align: center;
  margin: 150px;
  font-size: 10em;
  font-weight: bold;
  font-family: ChunkFive;
}

div#preview {
  height: 322px;
  background: url(/images/preview.jpeg) center center;
  background-repeat: no-repeat;
  position: relative;

  div#time1 {
    // color: #black;
    font-size: 9px;
    position: absolute;
    top: 100px;
    left: 224px;
    font-weight: bold;
  }

  div#time2 {
    color: #858585;
    font-size: 9px;
    position: absolute;
    top: 190px;
    left: 250px;
    font-weight: bold;
  }

  div#text {
    font-size: 15px;
    position: absolute;
    top: 220px;
    left: 110px;
    width: 250px;
  }
  
}

.bigtext {
  font-size: 18px;
}

#menu {
  min-height: 80px;

  .bordered a {
    border: 1px solid white;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 25px;
    margin-bottom: 25px;


    @media (min-width: 1200px) {
      margin-left: 30px;
    }
  }

  .login_button a {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: #bd0262;
    margin-left: 10px;
  }
}

@media (max-width: $screen-md-min) and (min-width: $screen-sm-min) {
  .navbar-header, .navbar-brand {
    width: 100%;
    text-align: center;
  }
  img#logo {
    display: inline-block;
    height: auto;
  }
}

div#preview div#text {
  font-size: 10px;
  line-height: 100%;
  margin-top: -2px;
}


.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}

.bs-callout-lm {
  border-left-color: $main-color;
}

.div_info {
  p {
    font-weight: bold;

    &:first-child {
      color: $main_color;
      margin-bottom: 0;

      & + p {
        font-weight: normal;
      }
    }
  }
}

#validated_transaction, #tel_type {
  h1 {
    color: $main-color;
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 40px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
  }

  .spacer {
    width: 100%;
    min-height: 30px;
    display: block;
  }

  ul {
    list-style: none;
    padding-left: 5px;
    padding-top: 20px;
  }
  label {
    font-weight: normal;
    cursor: pointer;
  }

  #valid_button_search_type {
    padding: 15px 80px;
    font-size: 1.5em;
    font-weight: bold;
  }

}

body.localiser {
  section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.spacer {
  width: 100%;
  min-height: 40px;
}

.small_text {

  font-size: 0.8em;
}

a.account_link {
  color: #333 !important;
  font-size: 0.8em !important;
}

#email-group {
  padding: 0 19px;
}

#loader {
  background-color: #111111;
  opacity: 0.96;
  width: 100vw;

  height: 100vh;
  position: fixed;
  text-align: center;
  z-index: 1000;
}

.loader_placer {
  position: fixed;
  left: calc(50% - 80px);
  top: calc(50% - 80px);

  p {
    margin-left: -80px;
    font-size: 16px;
    color: white;
    font-weight: bold;
  }

  .loader {
    z-index: 10000;

    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #FC0182; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

.upper {
  text-transform: uppercase;
}


#edit_number {
  height: 44px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #EEE;
  background-color: #eeeeee;

}

.invisible_feedback {
  width: 0;
}


#tutos_v2 {


  .affix {
    top: 250px;
    z-index: 999;
  }

  //1er resize pour les plus petits écrans
  @media (max-width: 1252px) {
    #tutos_menu {
      width: 295px;
      margin-top: 45px;
    }
  }

  //2nd resize pour téléphone et tablettes
  @media (max-width: $screen-sm-max) {
    #tutos_menu {
      width: auto;
      position: relative !important;
      margin-top: 45px;
    }
    .affix {
      top: 0;
    }
  }

  #title-First {
    display: inline-block;
  }

  padding-top: 50px;

  .visible {
    display: block;
  }

  .tutos_section {
    min-height: 1030px;
  }

  #tutos_menu {
    padding-top: 45px;

    a {
      color: black;
    }

    /* visited link */
    a.active {
      color: #fc0182;
    }
  }

  .container {
    min-height: 1250px;
  }


  #tutos_default_txt {
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 30px;
    text-align: center;
  }

  @media (max-width: $screen-sm-max) {
    #tutos_default_txt {
      padding-top: 0;
      padding-left: 0;
      margin-bottom: 0;
    }
  }

  .glyphicon-menu-down {
    color: #fc0182;
    font-size: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  #tutos-foot {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 50px 10px 50px 10px;
    margin-top: 50px;
  }

  /* décale l'image d'iphone sur ordinateur seulement*/
  @media (min-width: $screen-md-min) {
    #image-iphone {
      margin-left: 300px;
    }
  }

  @media (max-width: $screen-sm-max) {
    #tutos_menu {
      padding-top: 0;
    }
  }

  .txt_content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn-primary {
    width: 100px;
  }

  .section-disabled {
    display: none;
  }

  .label-default {
    height: 25px;
    font-size: 17px;
  }

  .spacer {
    width: 100%;
    min-height: 30px;
    display: block;
  }

  .link {
    display: inline;

    p {
      margin: 0;
    }
  }

  .text-indent {
    text-indent: 30px;
  }

  @media (max-width: $screen-sm-max) {
    .text-indent {
      text-indent: 0;
    }
  }
}

#tutos_v2_android {


  .affix {
    top: 250px;
    z-index: 999;
  }

  #tutos_android_menu {
    margin-top: 120px;
  }

  //1er resize pour les plus petits écrans
  @media (max-width: 1252px) {
    #tutos_android_menu {
      width: 240px;
      margin-top: 45px;
    }
  }

  //2nd resize pour téléphone et tablettes
  @media (max-width: $screen-sm-max) {
    #tutos_android_menu {
      width: auto;
      position: relative !important;
      margin-top: 45px;
    }
    .affix {
      top: 0px;
    }
  }

  /* visited link */
  a.active {
    color: #fc0182;
  }

  padding-top: 50px;

  .visible {
    display: block;
  }

  .glyphicon-menu-down {
    color: #fc0182;
    font-size: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .txt_content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #tutos_menu {
    padding-top: 45px;
  }

  #tutos_default_txt {
    padding-top: 10px;
    padding-left: 10px;
    text-align: center;
    margin-bottom: 30px;
  }

  @media (max-width: $screen-sm-max) {
    #tutos_default_txt {
      padding-top: 0px;
      padding-left: 0px;
      margin-bottom: 0px;
    }
  }

  .container {
    min-height: 700px;
  }

  #tutos-foot {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 50px 10px 50px 10px;
    margin-top: 50px;
  }

  @media (max-width: $screen-sm-max) {
    #tutos_menu {
      padding-top: 0px;
    }
  }

  .label-default {
    height: 25px;
    font-size: 17px;
  }

  #Second, #Third, #Four {
    display: none;
  }

  .spacer {
    width: 100%;
    min-height: 30px;
    display: block;
  }

  .link {
    display: inline;

    p {
      margin: 0;
    }
  }

}

.TriSea-technologies-Switch  {
  margin-top: 12px;
}

.TriSea-technologies-Switch > input[type="checkbox"] {
  display: none;   
}

.TriSea-technologies-Switch > label {
  cursor: pointer;
  height: 0px;
  position: relative; 
  width: 40px;  
}

.TriSea-technologies-Switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position:absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.TriSea-technologies-Switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.TriSea-technologies-Switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.TriSea-technologies-Switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.hiddenbase {display: none;}