body.tutorial {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-text-size-adjust: none;
  width: 100%;
  height: 100%;
  min-height: 100%;

  p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.9em;
    color: #333333;
  }

  a {
    color: #515f67;
    line-height: 12px;
    outline: none;
  }

  /*
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  */

  .bold {
    font-weight: 700;
  }

  .ir {
    border: 0;
    text-shadow: none;
    color: transparent;
    background-color: transparent;
    font-size: 0;
    text-indent: -999em;
  }

  hr {
    border: 2px solid #fc0182;
    margin: 40px auto;
    width: 200px;
  }

  .img-center {
    margin: auto;
  }

  .padding-0 {
    padding: 0 !important;
  }

  .padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  .max-width {
    max-width: 100%;
  }

  footer {
    padding: 80px 0;
  }

  footer h2 {
    color: #ffffff;
  }

  /* ------------------ */
  /* == Typography      */
  /* ------------------ */
  /* h1, .titleLevel1 {
      @include title(44px, 800, normal, $gray, true, false );
  } */
  h2 {
    font-family: "ChunkFive";
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    color: #333333;
    line-height: 32px;
    margin-top: 50px;
  }

  /*-------------
      HOME
  ---------------*/
  /* ------------------ */
  /* == HOME          */
  /* ------------------ */


  .page-tutoriel {

    background: #ffffff;

    .container {
      padding-left: 20px;
      padding-right: 15px;
    }

    .content-page {
      padding: 56px 0;

      p {
        color: #000000;
        font-family: Arial Narrow, Arial;
        font-size: 16px;
      }

      .list-tutoriel {
        padding-top: 20px;
        width: 85%;

        li {
          padding-left: 51px;
          padding-top: 9px;
          position: relative;
          margin-bottom: 33px;
          line-height: 1em;

          p {
            line-height: 1.3em;
            margin-bottom: 26px;

            .android-img {
              float: left;
              max-width: 70%;
            }

            span:first-child {
              background-color: #333;
              border-radius: 50%;
              color: #fff;
              font-size: 30px;
              font-weight: bold;
              height: 56px;
              left: -20px;
              line-height: 56px;
              position: absolute;
              text-align: center;
              top: 0;
              width: 56px;
            }

            strong {
              font-family: Arial;
              font-size: 19.4px;
              overflow: hidden;
            }
          }

          .icon-arrow {
            background: transparent url("/images/tutoriels/arrow.png") no-repeat scroll 0 0;
            width: 54px;
            height: 38px;
            margin: 41px auto 24px;
            display: block;
            background-size: 100%;
          }
        }
      }


      &.android-page {
        padding-top: 36px;

        .container {
          padding-left: 99px;
        }

        .intro-android {
          p {
            overflow: hidden;
            line-height: 1.6em;

            &:first-child {
              margin-bottom: 58px;
            }

            .android-img {
              float: left;
              max-width: 94px;
              margin-right: 27px;
            }

            strong {
              margin-top: 22px;
              float: left;
            }
          }
        }

        .list-tutoriel {

          li {
            margin-bottom: 57px;

            .icon-arrow {
              margin: 17px auto;
            }

            .img-iphone {
              max-width: 273px;
            }
          }
        }
      }
    }
  }


  .page-tutoriel .content-page a,
  .page-tutoriel .content-page .text-link {
    color: #fc0182;
  }


  @media screen and (max-width: 1400px) {
    .list-tutoriel li .img-iphone {
      max-width: 80% !important;
    }
  }

  @media screen and (max-width: 960px) {

    .page-tutoriel {
      .content-page {
        .container {
          padding-left: 15px !important;
        }

        p {
          font-size: 16px;
        }

        .list-tutoriel li {

          span:first-child {
            font-size: 25px;
            height: 50px;
            left: -10px;
            line-height: 50px;
            top: 8px;
            width: 50px;
          }

          .icon-arrow {
            width: 30px;
            height: 23px;
          }
        }

      }
    }

    footer img {
      max-width: 100%;
    }
  }
}


