
#geolocation-layout {

  .brand_container {
    background-color: #ff0080;
    padding: 5px 30px 5px 30px;
    height: 50px;
  }

  .brand {
    width: auto;
    max-height: 100%;
    display: block;
  }

  .full-container {
    background-image: url('/images/geolocInfo/map_bg.jpg');
    background-size: cover;
    height: calc(100vh - 50px);
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    text-align: center;
  }

  a.button-base {
    color: $color-blue;
  }

  .blue-text {
    color: $color-blue;
  }

  .modal-dialog {
    margin: 40px;
    margin-top: 30%;
  }

  .modal-header, .modal-footer {
    padding: 7px;
  }

  .modal-body {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (min-width: 768px) {
    .modal-dialog {
      width: 600px;
      margin: 30px auto;
      margin-top: 10%;
    }
  }

  .msg {
    display: none;

    &.active {
      display: block;
    }
  }

  .button_modal_part a {
    display: none;

    &.active {
      display: inline;
    }
  }

  .button_modal_part.empty {
    border-top: none;
  }

  #logo_big {
    margin: auto;
  }

  #logo {
    margin: auto;
  }

  .bs-callout {
    padding: 20px;
    margin: 20px 40px;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
  }

  .bs-callout-warning {
    border-left-color: #aa6708;
  }

  .error-message {
    color: #aa6708;
  }

  #askinfo_container label {
    color: white;
  }

  .white_text {
    color: white;
  }

  .spacer_v {
    min-height: 50px;
  }

  .btn-primary {
    color: #fff;
    background-color: #FC0182;
    border-color: #e30175;
  }


  .white_bg {
    background-color: white;
  }

  .bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
  }

  .bs-callout-danger {
    border-left-color: #ce4844;
  }

  #askinfo_container {

    .validation_button {
      padding-left: 30px;
      padding-right: 30px;
    }

    #submited_info {
      color: white;
      background-color: rgba(51, 51, 51, 0.62);
      border-radius: 20px;
      margin: 50px auto;
      padding: 20px;
    }

    .message-container {
      margin: 40px;
    }

    .modal-title {
      padding-left: 30px;
      padding-top: 20px;
    }
  }
}

